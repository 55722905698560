<template>
  <div class="auth-wrapper">
    <b-row class="auth-inner">
      <b-col sm="12" md="6" lg="3">
        <b-img src="@/assets/images/logo.svg" />

        <b-form class="auth-login-form" @submit.prevent="login">
          <b-form-group>
            <label for="login-email">
              Email <span class="required">*</span>
            </label>
            <b-form-input
              id="login-email"
              v-model="userEmail"
              name="login-email"
            />
          </b-form-group>

          <b-form-group>
            <label for="login-password">
              Password <span class="required">*</span>
            </label>
            <b-form-input
              id="login-password"
              v-model="password"
              type="password"
              class="form-control-merge"
              name="login-password"
            />
          </b-form-group>

          <b-alert variant="warning" :show="showError">Incorrect email or password.</b-alert>

          <div class="login-actions">
            <b-button type="submit" variant="success" block>
              Log in
            </b-button>
            <router-link class="forgot-password" to="/forgot-password"
              >Forgot your password?</router-link
            >
          </div>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BImg,
} from 'bootstrap-vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import Toast from '@/components/Toast.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BImg,
  },
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      password: '',
      userEmail: '',
      showError: false
    }
  },
  methods: {
    login() {
      this.$JWT.login({
        email: this.userEmail,
        password: this.password,
      }).then(response => {
        if (response.code !== 401) {
          this.$JWT.setToken(response.data.token)
          this.$JWT.setRefreshToken(response.data.token)
          this.$JWT.axiosIns
            .get(`${this.apiUrl}${response.data.user}`)
            .then(userResponse => {
              const userData = userResponse.data

              if (userData.role === 'ROLE_COURIER') {
                this.$router
                  .replace('/logout')
                  .then(() => {
                    this.$helper.showToast(this.$toast, Toast, 'You do not have the necessary rights. Use the mobile app.', '', 'error')
                  })
              }

              localStorage.setItem('userData', JSON.stringify(userData))
              this.$router
                .replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$helper.showToast(this.$toast, Toast, 'Welcome', '', 'success')
                })
            })
        }
      })
    },
  },
}
</script>
